import React, { useState } from 'react';
import { Button, Input, Row, Col, Steps, message,Select } from 'antd';
import { Stepper,StepLabel, } from '@mui/material';
import axios from 'axios';
import footerPatternImg from '../../assets/images/footer_pattern.png';
import DraggerField from './DraggerField';
import "../../App.css"

const { Step } = Steps;

const initialFormData = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  linkedIn: '',
  phoneNumber: '',
  messageToManager: '',
  messageToHiringManager: '',
  file: null,
  rubyOnRailsExperience: '',
  otherFrameworkExperience: '',
  leadDesignArchitecture: '',
  currentCTC: '',
  expectedCTC: '',
  noticePeriod: '',
  servingNoticePeriod: '',
  joiningTime: '',
  // Define additional fields...
};

const customStyles = {
  label: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333333',
    marginBottom: '6px',
  },

  button: {
    marginTop: '20px',
    marginBottom: '20px',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    background: '#007bff',
    color: '#ffffff',
    padding: '10px 15px',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
    fontSize: '16px',
    fontWeight: '500',
  },
  inputContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
    marginBottom: '20px',
  },
  input: {
    minWidth: '280px', // Ensure inputs do not become too small on narrow screens
    padding: '12px',
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    borderRadius: '5px',
    border: '1px solid #cccccc',
    fontSize: '15px',
  },
  formSection: {
    textAlign: 'left',
    maxWidth: '800px', // Set a max-width to prevent the form from spanning too wide on large screens
    margin: 'auto',
    padding: '20px',
  },
  sectionTitle: {
    textAlign: 'center',
    margin: '20px 0',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#007bff',
  },
};

function ApplicationForm() {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState(initialFormData);

  const steps = [
    'Personal Details',
    'Professional Experience',
    // Define other steps here...
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    // Implement submission logic...
  };

  const handleNext = () => setCurrentStep((prev) => prev + 1);
  const handleBack = () => setCurrentStep((prev) => prev - 1);

  return (
    <>
      <div className="pageBanner">
        <img src={footerPatternImg} className="pagebanner-img" alt="" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="pageBannerHead">
                <h2>Job openings </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div>
        <Stepper activeStep={currentStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
        {currentStep === 0 && (
  <>
    <div style={customStyles.inputContainer}>
      <h2>Personal Information</h2>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={12}>
            {/* Adding a label with a mandatory star */}
            <div>
              <label style={customStyles.label}>First Name <span className="mandatory-star">*</span></label>
              <Input name="firstName" value={formData.firstName} onChange={handleChange} />
            </div>
        </Col>
        <Col span={12}>
            <div>
              <label style={customStyles.label}>Last Name <span className="mandatory-star">*</span></label>
              <Input name="lastName" value={formData.lastName} onChange={handleChange} />
            </div>
        </Col>
      </Row>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={12}>
            <div>
              <label style={customStyles.label}>Email <span className="mandatory-star">*</span></label>
              <Input name="email" value={formData.email} onChange={handleChange} />
            </div>
        </Col>
        <Col span={12}>
            <div>
              <label style={customStyles.label}>Confirm Email <span className="mandatory-star">*</span></label>
              <Input name="confirmEmail" value={formData.confirmEmail} onChange={handleChange} />
            </div>
        </Col>
      </Row>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={12}>
            <div>
              <label style={customStyles.label}>LinkedIn <span className="mandatory-star">*</span></label>
              <Input name="linkedIn" value={formData.linkedIn} onChange={handleChange} />
            </div>
        </Col>
        <Col span={12}>
            <div>
              <label style={customStyles.label}>Phone Number <span className="mandatory-star">*</span></label>
              <Input name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
            </div>
        </Col>
      </Row>
    </div>
              <h2 style={{ textAlign: 'center' }}>Resume <span className="mandatory-star">*</span></h2>
              <DraggerField
                style={customStyles.input}
                name="file"
                field={{ name: 'file', value: formData.file }}
                form={{ setFieldValue: setFormData }}
              />

              <div style={customStyles.inputContainer}>
                <h2>Message to Manager</h2>
                <Input.TextArea
                  style={{ width: '100%' }} // Ensure the textarea spans the full container width
                  placeholder="Message to Manager"
                  name="messageToManager"
                  value={formData.messageToManager}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
          {/* Control buttons */}
          <div style={{ textAlign: 'center' }}>
          {currentStep === 1 && (
  <>
    <div style={customStyles.inputContainer}>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={12}>
          <label style={customStyles.label} htmlFor="rubyOnRailsExperience">Years of Ruby on Rails Experience:</label>
          <Input
            name="rubyOnRailsExperience"
            value={formData.rubyOnRailsExperience}
            onChange={handleChange}
            
          />
        </Col>
        <Col span={12}>
          <label style={customStyles.label} htmlFor="otherFrameworkExperience">Other Frameworks Experience:</label>
          <Input
            name="otherFrameworkExperience"
            value={formData.otherFrameworkExperience}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={12}>
          <label style={customStyles.label} htmlFor="leadDesignArchitecture">Lead Design Architecture Experience:</label>
          <Select
            defaultValue=""
            style={{ width: '100%' }}
            onChange={value => setFormData({ ...formData, leadDesignArchitecture: value })}
          >
            <Select.Option value="yes">Yes</Select.Option>
            <Select.Option value="no">No</Select.Option>
          </Select>
        </Col>
        <Col span={12}>
          <label style={customStyles.label} htmlFor="currentCTC">Current CTC:</label>
          <Input
            name="currentCTC"
            value={formData.currentCTC}
            onChange={handleChange}
          />
        </Col>
      </Row>
      {/* Additional fields */}
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={12}>
          <label style={customStyles.label} htmlFor="expectedCTC">Expected CTC:</label>
          <Input
            name="expectedCTC"
            value={formData.expectedCTC}
            onChange={handleChange}
          />
        </Col>
        <Col span={12}>
          <label style={customStyles.label} htmlFor="noticePeriod">Notice Period (in days):</label>
          <Input
            name="noticePeriod"
            value={formData.noticePeriod}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ width: '100%' }}>
        <Col span={12}>
          <label style={customStyles.label} htmlFor="servingNoticePeriod">Serving Notice Period:</label>
          <Select
            defaultValue=""
            style={{ width: '100%' }}
            onChange={value => setFormData({ ...formData, servingNoticePeriod: value })}
          >
            <Select.Option value="yes">Yes</Select.Option>
            <Select.Option value="no">No</Select.Option>
          </Select>
        </Col>
        <Col span={12}>
          <label style={customStyles.label} htmlFor="joiningTime">Joining Time (in days):</label>
          <Input
            name="joiningTime"
            value={formData.joiningTime}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </div>
  </>
)}


  {/* Control buttons */}
  <div style={{ marginTop: '20px' }}>
    {currentStep < steps.length - 1 && (
      <Button style={customStyles.button} onClick={handleNext}>Next</Button>
    )}
    {currentStep > 0 && (
      <span style={{ paddingRight: '10px' }}> {/* Adjust padding as needed */}
      <Button style={customStyles.button} onClick={handleBack}>Back</Button>
    </span>
    )}
    {currentStep === steps.length - 1 && (
      <Button style={customStyles.button} onClick={handleSubmit}>Submit</Button>
    )}
  </div>
</div>
</div>
      </div>
    </>
  );
}

export default ApplicationForm;
