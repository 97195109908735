import React from 'react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const DraggerField = ({ field, form, ...props }) => {
  const { setFieldValue } = form;

  const handleChange = (info) => {
    const fileList = [...info.fileList];
    setFieldValue(field.name, fileList);
  };

  return (
    <Dragger {...props} fileList={field.value} onChange={handleChange}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
    </Dragger>
  );
};

export default DraggerField;

