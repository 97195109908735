import React, { useEffect, useState } from 'react';
import {  animated } from 'react-spring';
import footerPatternImg from '../../assets/images/footer_pattern.png';


const ContactPage = () => {


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
    <div className="pageBanner">
      <img src={footerPatternImg} className="pagebanner-img" alt="" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12">
            <div className="pageBannerHead">
              <animated.h2>Contact</animated.h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
    <div className="contact-page-container">
      <div className="content-container">
        {/* Centered iframe container */}
        <div className="container">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSf0R1lc_rpruum_GPez0_hFXWwhzh8McOF8iIF15deDr6SNUw/viewform?embedded=true"
            width="640"
            height="1300"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="Contact Form"
            scrolling='no'
          >
            Loading…
          </iframe>
        </div>
      </div>
    </div>
  </div>
  </div>
  );
};

export default ContactPage;
